import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import ScorecardTemplatesDetails from 'src/components/scorecard-templates-details/scorecard-templates-details';
import SEO from 'src/components/seo';

const CreateTemplate = (props) => {
  return (
    <Layout>
      <SEO title="Create Scorecard Template" />
      <PrivateRoute>
        <ScorecardTemplatesDetails {...props} />
      </PrivateRoute>
    </Layout>
  );
};

export default CreateTemplate;
